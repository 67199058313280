window.autohideonscroll = () => {
   return {
      lastScrollTop: 0,
      delta: 15,

      get scrollTop() {
         return (
            (window.pageYOffset || document.documentElement.scrollTop) -
            (document.documentElement.clientTop || 0)
         );
      },

      init() {
         this.scroll();
      },

      scroll() {
         const scrollTop = this.scrollTop;

         if (Math.abs(this.lastScrollTop - scrollTop) <= this.delta) {
            return;
         }

         const hidden =
            scrollTop > this.lastScrollTop && this.lastScrollTop > 0;

         document.body.setAttribute("data-autohideonscroll", hidden);

         this.lastScrollTop = scrollTop;
      },
   };
};
