window.post_item = (postId, rating = 0, replies = 0, views = 0) => {
	return {
		postId,
		rating,
		replies,
		views,
		replying: false,
		voted: 0,

		init() {
			this.replying = Boolean(localStorage[`post_form_body_${postId}`]);
		},

		vote(vote) {
			this.replying = true;
			this.voted = vote;

			this.$dispatch("post_item:react", {
				postId: this.postId,
			});
		},

		reply() {
			this.$dispatch("post_item:reply", {
				postId: this.postId,
			});
		},

		showViews() {
			this.$dispatch("post_item:views", {
				postId: this.postId,
			});
		},

		showActions() {
			this.$dispatch("post_item:actions", {
				postId: this.postId,
			});
		},

		submitted() {
			this.replying = false;
		},
	};
};
