window.feed = () => {
	return {
		scrollY: -1,
		innerHeight: -1,
		itemsHeight: -1,
		top: -1,
		width: -1,
		height: -1,
		start: -1,
		end: -1,
		margin: -1,
		page: 1,
		fetching: false,

		init() {
			this.update();
		},

		scroll() {
			this.update();
		},

		resize() {
			this.update();
		},

		update() {
			this.measure();
			this.fetch();
		},

		measure() {
			const rect = this.$root.getBoundingClientRect();

			this.scrollY = window.scrollY;
			this.innerHeight = window.innerHeight;
			this.top = rect.top + this.scrollY;
			this.width = rect.width;
			this.height = rect.height;
			this.margin = this.innerHeight;
			this.itemsHeight =
				this.$root.offsetHeight > this.innerHeight
					? this.$root.offsetHeight
					: this.innerHeight;

			const start = Math.floor(this.scrollY - this.top - this.margin);
			this.start = start > 0 ? start : 0;

			this.end = Math.floor(
				this.start + this.innerHeight + this.margin * 2,
			);
		},

		render(html) {
			this.$root.insertAdjacentHTML("beforeend", html);
		},

		async fetch() {
			if (this.fetching) {
				return;
			}

			if (this.end > this.itemsHeight - this.innerHeight) {
				this.fetching = true;

				try {
					this.render(await this.fetchPageItems(this.page + 1));

					this.page = this.page + 1;
				} finally {
					this.fetching = false;
				}
			}
		},

		async fetchPageItems(page) {
			const html = await (
				await fetch(location.href, {
					method: "POST",
					body: new URLSearchParams({ page }),
				})
			).text();

			const doc =
				document.implementation.createHTMLDocument().documentElement;

			doc.innerHTML = html;

			return doc.querySelector('[x-data="feed"]').innerHTML;
		},
	};
};
