window.post_form = (id = "") => {
	return {
		maxLength: 500,
		length: 0,
		percent: 1,
		submitting: false,

		set body(value) {
			localStorage[`post_form_body_${id}`] = value;

			this.input();

			const element = this.$refs.body;

			if (document.activeElement !== element) {
				element.value = value;
				this.length = 0;
				this.resize();
			}
		},

		get body() {
			return localStorage[`post_form_body_${id}`] || "";
		},

		get filled() {
			return this.length > 0;
		},

		get left() {
			return this.maxLength - this.length;
		},

		get statusShowed() {
			return this.left <= this.maxLength / 2;
		},

		get bodyDisabled() {
			return this.submitting;
		},

		get submitDisabled() {
			return this.left < 0 || this.length < 2 || this.submitting;
		},

		get styleProps() {
			return `--progress-circle-percent: ${this.percent}`;
		},

		get status() {
			if (this.percent > 0.25) {
				return "minimal";
			} else if (this.percent > 0.1) {
				return "medium";
			} else if (this.percent > 0 || this.left >= 0) {
				return "full";
			}

			return "overlap";
		},

		init() {
			this.input();
			this.resizeOnInit();
		},

		focus() {
			this.filled = true;
		},

		input() {
			this.length = this.body.length;

			this.percent =
				Math.round(
					((this.maxLength - this.length) * 100) / this.maxLength,
				) / 100;
		},

		resize() {
			const element = this.$refs.body;

			element.style.height = "auto";
			element.style.height = element.scrollHeight + "px";
		},

		resizeOnInit() {
			requestAnimationFrame(() => this.resize());
			setTimeout(() => requestAnimationFrame(() => this.resize()), 100);
		},

		async submit() {
			return new Promise((resolve) => {
				this.submitting = true;

				this.$dispatch("post_form:submit");
				this.$dispatch("post_form:submitting");

				setTimeout(() => {
					this.submitting = false;
					this.body = "";

					this.$dispatch("post_form:submit");
					this.$dispatch("post_form:submitted");
				}, 2000);
			});
		},
	};
};
