window.post_feed = () => {
	return {
		postId: null,
		modalShowing: false,
		modalHtml: "",

		async actionsHtml() {
			return this.$refs.actions.innerHTML;
		},

		async showActions(postId) {
			this.postId = postId;
			this.modalShowing = true;
			this.modalHtml = await (
				await fetch("/post/actions", { method: "POST" })
			).text();
		},

		async showViews(postId) {
			this.postId = postId;
			this.modalShowing = true;
			this.modalHtml = await (
				await fetch("/post/views", { method: "POST" })
			).text();
		},

		hideModal() {
			this.postId = null;
			this.modalShowing = false;
			this.modalHtml = "";
		},

		edit() {
			alert(`edit: ${this.postId}`);
			this.hideModal();
		},

		bookmark() {
			alert(`bookmark: ${this.postId}`);
			this.hideModal();
		},

		report() {
			alert(`report: ${this.postId}`);
			this.hideModal();
		},

		block() {
			alert(`block: ${this.postId}`);
			this.hideModal();
		},

		link() {
			alert(`link: ${this.postId}`);
			this.hideModal();
		},
	};
};
