window.autoheight = () => {
	return {
		init() {
			requestAnimationFrame(() => this.resize());
			setTimeout(() => requestAnimationFrame(() => this.resize()), 100);
		},

		resize() {
			this.$el.style.height = "auto";
			this.$el.style.height = this.$el.scrollHeight + "px";
		},
	};
};
